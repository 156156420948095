.home_loading_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 2000;
  transition: all 1.25s;
}
.hidden {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.home_loading_container .obj {
  width: 6px;
  height: 40px;
  background-color: #272727;
  margin: 0 3px;
  border-radius: 10px;
  animation: loading 0.8s linear infinite;
  transform-origin: bottom;
}

.m_right_2 {
  margin-right: 0.7rem !important;
}

.m_right_2 span {
  vertical-align: middle;
}

@keyframes loading {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

.home_loading_container .obj:nth-child(2) {
  animation-delay: 0.1s;
}

.home_loading_container .obj:nth-child(3) {
  animation-delay: 0.2s;
  background-color: #df1b23;
}

.home_loading_container .obj:nth-child(4) {
  animation-delay: 0.3s;
  background-color: #df1b23;
}

.home_loading_container .obj:nth-child(5) {
  animation-delay: 0.4s;
}

.home_loading_container .obj:nth-child(6) {
  animation-delay: 0.5s;
  background-color: #df1b23;
}

.home_loading_container .obj:nth-child(7) {
  animation-delay: 0.6s;
}

.home_loading_container .obj:nth-child(8) {
  animation-delay: 0.7s;
  background-color: #df1b23;
}
