:root {
  --primaryColor: #df1b23;
  --secondaryColor: #000000;
  --cyanColor: #00ffff;
  --greyColor: #383838;
}

.scale-md {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.lh-15 {
  line-height: 1.5;
}

.text-underline {
  text-decoration: underline !important;
}

.bg-soft-success {
  background-color: #d6fde2;
  color: #2b9143;
}

.bg-soft-danger {
  background-color: #fddcdc;
  color: #c10000;
}

.articles .inner-banner-wrap .inner-baner-container {
  background: linear-gradient(to bottom, #00000028, #df1b2225),
    url("../assets/images/central.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.articles .inner-banner-wrap .inner-baner-container .inner-banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.articles .inner-banner-wrap .inner-baner-container .banner-search-form {
  width: 100%;
  max-width: 700px;
}

.articles
  .inner-banner-wrap
  .inner-baner-container
  .banner-search-form
  .input-wrapper {
  position: relative;
}

.articles
  .inner-banner-wrap
  .inner-baner-container
  .banner-search-form
  .input-wrapper
  .icon {
  position: absolute;
  top: 50%;
  left: 1rem;
  -webkit-transform: translateY(-50%) scaleX(-1);
  transform: translateY(-50%) scaleX(-1);
  color: #f8595e;
  font-size: 1.3rem;
}

.articles
  .inner-banner-wrap
  .inner-baner-container
  .banner-search-form
  .input-wrapper
  input {
  padding: 1.8rem 1rem 1.8rem 3rem;
  font-size: 1.3rem;
  border-radius: 50px;
  opacity: 0.8;
}

.articles
  .inner-banner-wrap
  .inner-baner-container
  .banner-search-form
  .input-wrapper
  input::placeholder {
  color: #ff777c;
}

.blog-item .footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.blog-item .footer-wrapper .links {
  font-size: 18px;
}

.blog-item .footer-wrapper .links a {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #df1b23;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  vertical-align: middle;
  color: #ffffff;
  transition: all 0.5s;
}

.blog-item .footer-wrapper .links a.facebook {
  background-color: #06619e;
}

.blog-item .footer-wrapper .links a.facebook:hover {
  background-color: #2b93d8;
}

.blog-item .footer-wrapper .links a.whatsapp {
  background-color: #2cc505;
}

.blog-item .footer-wrapper .links a.whatsapp:hover {
  background-color: #4aeb22;
}

.blog-item .footer-wrapper .links a.twitter {
  background-color: #36a3eb;
}

.blog-item .footer-wrapper .links a.twitter:hover {
  background-color: #59ade6;
}

.blog-item .footer-wrapper .links a:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.blog-item .footer-wrapper .read-more {
  color: #df1b23;
  font-size: 14px;
  font-weight: 700;
}

.blog-item .footer-wrapper .read-more:hover {
  color: #f0575c;
  font-size: 14px;
  font-weight: 700;
}

.blog-item figure {
  position: relative;
  overflow: hidden;
}

.blog-item figure a img {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: all 0.6s;
}

.blog-item figure a img:hover {
  transform: scale(1.08);
}

.blog-item .entry-meta .rating .fav,
.rating .fav {
  color: #f1960c !important;
}

.side-content .part-title {
  text-align: left !important;
  width: 100%;
  border: none !important;
  padding: 0 !important;
}
.pagination {
  flex-wrap: wrap;
  gap: 10px;
}
.pagination li {
  list-style: none;
}
.blog-item .footer-wrapper .links a {
  margin: 3px;
}

/*////////////////////////// about us //////////////////////////*/

.section-disc {
  font-size: 18px;
  margin-bottom: 2rem;
}

.about-service .about-service-icon {
  border-radius: 50% !important;
}

.info-section {
  position: relative;
  background-attachment: fixed;
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: #ffffff;
  overflow: hidden;
}

.info-section .head p {
  font-size: 2.5rem;
}

.info-section .image {
  position: relative;
}

.info-section .image img:not(.img-fluid) {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.info-section .text {
  padding: 0 3rem;
}

.info-section .text .head {
  max-width: 550px;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.info-section .text .head p {
  font-weight: 700;
}

.info-section .text .short-desc {
  margin-bottom: 1.5rem;
  max-width: 450px;
  color: #646464;
}

.info-section .info-btn {
  display: inline-block;
  background-color: #f56960;
  color: #ffffff;
  padding: 1rem 1.6rem;
  border-radius: 50px;
  margin-top: 0.5rem;
  transition: all 0.6s;
}

.info-section .info-btn:hover {
  background-color: #e05146;
}

/*////////////////////////// testimonials //////////////////////////*/

.testimonial-item {
  padding: 1.5rem;
  font-size: 16px;

  border-radius: 8px;
  margin-bottom: 30px;
  box-shadow: 0 5px 5px #cfcfcf;
}

.writer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .text {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  max-height: 96px;
} */

.writer img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.writer .data {
  margin-left: 10px;
}

.writer .data:lang(ar) {
  margin-left: 0;
  margin-right: 10px;
}

.writer .name {
  margin-bottom: 0;
  color: #010758;
  font-weight: 600;
}

.writer .position {
  margin-bottom: 0;
  font-size: 0.925rem;
}

/*////////////////////////// career //////////////////////////*/

.career-page-section .vacancy-content {
  box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.176);
  border-radius: 5px;
  padding: 25px;
  position: relative;
}

.career-page-section .vacancy-content .title {
  font-size: 18px !important;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}

.career-page-section .vacancy-content .type,
.career-page-section .vacancy-content .status {
  font-size: 12px !important;
  margin-bottom: 10px;
  color: #858585;
  font-weight: 500 !important;
}

.theme-btn {
  position: relative;
  border-radius: 50px;
  transition: all 0.4s;
  background-color: var(--primaryColor) !important;
  padding: 15px 30px !important;
}

.theme-btn:hover {
  background-color: #ff3022 !important;
  -webkit-transform: scale(1.1);
  transform: scale(1.05);
}

.theme-form-btn {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 1rem 2rem;
  background-color: #fc695f;
  color: #ffffff;
  border-radius: 50px;
  margin-bottom: 2.5rem;
  margin-top: 1.8rem;
}

.theme-form-btn:hover {
  background-color: #ff3022;
  -webkit-transform: scale(1.1);
  transform: scale(1.05);
}

.about-service .about-service-icon {
  background-color: #ff3022;
  border-radius: 50%;
}

.career-detail-section .fa-facebook {
  color: #045bac;
}

.career-detail-section .fa-whatsapp {
  color: #0bc404;
}

.career-detail-section .fa-linkedin {
  color: #18607c;
}

.career-detail-section .fa-instagram {
  color: #d82e10;
}

.text-main-dark {
  color: #f33c2f;
  font-weight: 700;
}

.bg-main {
  background-color: #f55a5f;
  color: #ffffff;
}

.text-main {
  color: #f56960;
}

.bg-main {
  background-color: #f56960 !important;
}

.widget-bg {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 3px solid #f56960 !important;
}

.career-detail-section .job-description {
  background-color: transparent;
}

.career-detail-section .job-image img {
  box-shadow: 0 0 5px #969696;
  border-radius: 10px;
}

.vacancy-form .input-wrapper {
  position: relative;
}

.vacancy-form .input-wrapper .icon {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #de1c1c !important;
}

.vacancy-form .input-wrapper input {
  padding-left: 40px;
  border-radius: 7px !important;
  outline: none;
  box-shadow: none;
}

.file-input-wrapper {
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-input-wrapper input {
  padding-left: 40px;
  border: none;
  background-color: transparent;
}

.file-input-wrapper button {
  margin-bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.file-input-wrapper .icon {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #de1c1c !important;
}

.apply-job-form .theme-form-btn {
  display: block;
  background-color: #df1b23 !important;
  border-radius: 7px;
  transition: all 0.4s;
}

.apply-job-form .theme-form-btn[disabled] {
  background-color: #ff7070 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  cursor: not-allowed;
}

.upload-btn {
  background-color: #7e7e7e !important;
  padding: 1rem 1.5rem;
  border: none;
  color: #ffffff;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.5s;
}

.upload-btn:hover {
  background-color: #a3a2a2 !important;
}

.application-modal .modal-header {
  border-bottom: none;
}

/*////////////////////////// find agent //////////////////////////*/

.map-wrapper iframe {
  box-shadow: 0 0 5px #d1d1d1;
  border-radius: 10px;
}

select {
  /* height: 100% !important; */
  outline: none !important;
  box-shadow: none !important;
}

.form-control {
  border-radius: 5px !important;
  outline: none !important;
  box-shadow: none !important;
}

.search-btn,
.search-address-btn {
  display: block;
  width: 100%;
  padding: 0.85rem 0.5rem;
  text-align: center;
  border-radius: 6px;
  background-color: #df1c0e;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #ffffff;
  transition: all 0.5s;
}

.search-btn[disabled],
.search-address-btn[disabled] {
  background-color: #f5766d !important;
}

.search-btn[disabled]:hover,
.search-address-btn[disabled]:hover {
  background-color: #f5766d !important;
}

.search-address-btn {
  position: static !important;
}

.search-btn:hover,
.search-address-btn:hover {
  background-color: #b4190e !important;
}
.entry-meta p a span {
  font-size: "0.8rem";
  line-height: "1.3";
}
.entry-meta p a span::before {
  content: "" !important;
}

.newsroom-cards-section .newsroom-card {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 8px 5px #eeeeee;
  background: #ffffff !important;
  margin-bottom: 3rem;
}

.newsroom-cards-section .newsroom-card figure {
  overflow: hidden;
}

.newsroom-cards-section .newsroom-card img {
  display: block;
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 0.4s;
}

.newsroom-cards-section .newsroom-card img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.newsroom-card .newsroom-title {
  text-align: center;
  padding: 2rem 1rem;
}

.newsroom-card .newsroom-title h4 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.newsroom-card .newsroom-title a {
  transition: all 0.4s;
}

.newsroom-card .newsroom-title a:hover {
  color: #f56960;
}

.partners-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.partners-container .partner,
.partner {
  position: relative;
  box-shadow: 0 0 10px #cccccc;
  margin-bottom: 1.25rem;
  padding: 1rem;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  transition: all 0.4s;
}

.partners-container .partner:hover,
.partner:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.partners-container .partner:last-child {
  margin-right: 0;
}

.partners-container img {
  width: 120px;
  height: auto;
  border-radius: 10px;
}

.partner.featured {
  position: relative;
  text-align: center;
  width: 220px;
  height: 220px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.partner.featured .icon {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 30px;
  height: auto;
}

.filter-form select,
.filter-form input {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 6px !important;
}

.video-item .iframe-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  box-shadow: 0 5px 5px #d1d1d1;
  border-radius: 6px;
  margin-bottom: 1.5rem;
}

.video-item .iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.video-item .title {
  color: #000000;
  z-index: 100;
  text-align: center;
  font-size: 22px;
}

/* Filter Form Styles */

.filter-form {
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .filter-form {
    margin-bottom: 10px;
  }
}

.filter-form .form-group {
  overflow: auto;
}

.filter-form .btn-group {
  justify-content: center;
}

.filter-form .form-group::-webkit-scrollbar {
  opacity: 0;
}

.filter-form .btn-group .filter-btn {
  display: inline-block;
  background-color: transparent;
  padding: 10px 15px;
  margin-right: 20px;
  border: 1px solid #df1b23;
  color: #df1b23;
  border-radius: 10px !important;
  min-width: 130px !important;
  max-width: 130px !important;
  margin-top: 10px;
  outline: none !important;
  box-shadow: none;
  transition: all 0.4s;
}

.filter-form .filter-btn.active,
.filter-form .filter-btn:hover {
  background-color: #df1b23;
  color: #ffffff;
}

.infographic-item {
  position: relative;
  cursor: pointer;
  height: 100%;
}

.infographic-item .title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
}

.infographic-item figure {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 15px #888888;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .infographic-item figure {
    max-width: 400px;
  }
}

.infographic-item figure .overlay {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  z-index: 10;
  transition: all 0.7s;
}

.infographic-item figure .overlay button,
.infographic-item figure .overlay a {
  display: block;
  border: 1px solid #fc8589;
  width: 160px;
  text-align: center;
  color: #fc8589;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  transition: all 0.4s;
}

@media screen and (max-width: 575px) {
  .infographic-item figure .overlay button,
  .infographic-item figure .overlay a {
    width: 100px;
    padding: 5px;
    font-size: 10px;
  }
}

.infographic-item figure .overlay a {
  margin-bottom: 0;
}

.infographic-item figure .overlay button:hover,
.infographic-item figure .overlay a:hover {
  display: block;
  background-color: #df1b23;
  color: #ffffff;
}

.infographic-item figure > img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

.infographic-item figure::before,
.infographic-item figure::after {
  content: "";
  position: absolute;
  left: -60%;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #000000bb;
  transition: all 0.5s;
}

.infographic-item figure::after {
  left: auto;
  right: -60%;
}

.infographic-item figure:hover::before {
  left: 0;
}

.infographic-item figure:hover::after {
  right: 0;
}

.infographic-item figure:hover .overlay {
  top: 0;
}

.image-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  visibility: hidden;
  justify-content: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  align-items: flex-start;
  overflow: auto;
  background-color: #000000c2;
  padding-top: 100px;
  padding-bottom: 60px;
  z-index: 1000;
  transition: all 0.6s;
}

.image-backdrop img {
  width: 90%;
  max-width: 650px;
  height: auto;
  z-index: 500;
  border-radius: 10px;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 1.5s;
}

.close-backdrop {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  font-size: 30px;
  z-index: 500;
}

.image-backdrop.shown {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.image-backdrop.shown img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.close-backdrop:hover {
  -webkit-transform: scale(1.03) rotateZ(90deg);
  transform: scale(1.03) rotateZ(90deg);
  backface-visibility: hidden;
}

.post-navigation-wrap {
  margin-top: 20px;
  margin-bottom: 30px;
}

/* Filter Form Styles */

.filter-form {
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .filter-form {
    margin-bottom: 10px;
  }
}

.filter-form .form-group {
  overflow: auto;
}

.filter-form .btn-group {
  justify-content: center;
}

.filter-form .form-group::-webkit-scrollbar {
  opacity: 0;
}

.filter-form .btn-group .filter-btn {
  display: inline-block;
  background-color: transparent;
  padding: 10px 15px;
  margin-right: 20px;
  border: 1px solid #df1b23;
  color: #df1b23;
  border-radius: 10px !important;
  min-width: 130px !important;
  max-width: 130px !important;
  margin-top: 10px;
  outline: none !important;
  box-shadow: none;
  transition: all 0.4s;
}

.filter-form .filter-btn.active,
.filter-form .filter-btn:hover {
  background-color: #df1b23;
  color: #ffffff;
}

/* Standard Loading Overlay */

.central-section {
  position: relative;
}

.central-section .loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: flex-start;
  padding-top: 80px;
  background-color: #ffffff;
  transition: all 0.4s;
}

.central-section .loading-overlay img {
  width: 50px;
  height: auto;
}

.media-coverages a {
  font-size: 14px;
}

.press-item {
  position: relative;
  height: 100%;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 45px;
}

.press-item:first-child {
  border-bottom: none;
}

.press-item .image {
  min-height: 250px;
  height: 100%;
  overflow: hidden;
  transition: all 0.4s;
}

.press-item .image:hover {
  box-shadow: 0 5px 5px #7e7e7e;
}

.press-item .image img {
  width: 100% !important;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  transition: all 0.4s;
}

@media screen and (max-width: 768px) {
  .press-item .image img {
    margin-bottom: 20px;
  }
  .press-item .image img {
    width: 100% !important;
    height: auto;
  }
}

.press-item .image img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.offer-form-wrapper {
  background-color: #efefef;
  padding: 2rem;
  border-radius: 10px;
}

.offer-form-wrapper input,
.offer-form-wrapper button {
  border-radius: 5px !important;
}

.offer-item {
  position: relative;
  height: 100%;
}

.offer-item .image {
  min-height: 250px;
  /* height: 100%; */
  overflow: hidden;
  transition: all 0.4s;
}

.offer-item .image:hover {
  box-shadow: 0 5px 5px #7e7e7e;
}

.offer-item .image img {
  width: 600px !important;
  /* height: 100%; */
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  transition: all 0.4s;
}

@media screen and (max-width: 768px) {
  .offer-item .image img {
    margin-bottom: 20px;
  }
  .offer-item .image img {
    width: 100% !important;
    height: auto;
  }
}

.offer-item .image img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.main-action-btn {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #df1b23;
  color: #ffffff;
  outline: none;
  border: none;
  box-shadow: none;
  position: relative;
  transition: all 0.4s;
}

.main-action-btn:hover {
  background-color: #f8575c;
}

.main-action-btn[disabled] {
  background-color: #f55a5f;
  cursor: not-allowed;
}

.thm-input-wrapper {
  position: relative;
}

.thm-input-wrapper input {
  padding-left: 2.5rem;
  outline: none !important;
  box-shadow: none !important;
}

.thm-input-wrapper .icon {
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.text-main {
  color: #df1b23 !important;
}

.text-main-light {
  color: #fcdddf !important;
}

.read-more-link {
  color: #df1b23;
  transition: all 0.4s;
}

.read-more-link:hover {
  color: #f7787c;
}

.contact-detail-wrap .details-list li {
  align-items: stretch;
}

/* .contact-detail-wrap .details-list li .icon {
    width: 65px;
    height: 75px !important;
    line-height: 75px !important;
    font-size: 2.5rem;
  } */

.contact-detail-wrap {
  padding-left: 40px !important;
}

@media screen and (max-width: 991px) {
  .contact-detail-wrap {
    padding-left: 0 !important;
  }
}

.sales-team-wrapper .sales-member {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
}

.sales-team-wrapper .sales-member:last-child {
  margin-bottom: 0;
}

.sales-team-wrapper .sales-member .image {
  margin-right: 12px;
}

.sales-team-wrapper .sales-member .image img {
  width: 105px;
  height: auto;
  max-width: 105px;
}

@media screen and (max-width: 375px) {
  .sales-team-wrapper .sales-member .text p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .sales-team-wrapper .sales-member .text .email {
    display: inline-block;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.sales-member {
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 0 5px #cccccc;
}

.sales-member .image {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: #d8d8d8;
  text-align: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.sales-member .image img {
  width: 130px;
  height: 130px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 50%;
}

.sales-member .name {
  padding-right: 25px;
  padding-left: 25px;
  margin-bottom: 20px;
}

.sales-member .details {
  padding: 5px 25px;
}

.sales-member .details li {
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.sales-member .details li::marker {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: #dc3545;
}

.sales-member .details li.title::marker {
  content: "\f507";
}

.sales-member .details li.phone::marker {
  content: "\f095";
}

.sales-member .details li.email {
  text-decoration: underline;
}

.sales-member .details li.email::marker {
  content: "\f0e0";
}

@media screen and (max-width: 375px) {
  .sales-member .text p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .sales-member .text .email {
    display: inline-block;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.click-page {
  cursor: pointer;
}
.mile-select {
  height: 45px;
}

.contactus-form {
  box-shadow: 0 0 5px #d1d1d1;
  border-radius: 7px;
  padding: 30px;
}

.contact-result-message-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #0000007a;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0.95;
  z-index: 20000;
  top: 0;
  left: 0;
  transition: all 0.9s;
}

.contact-result-message {
  width: 100%;
  max-width: 600px;
  padding: 50px;
  background-color: #ffffff;
  box-shadow: 0 0 5px #eeeeee;
  border-radius: 7px;
}

.contact-result-message.success {
  border: 2px solid #95ff92;
}

.contact-result-message.failure {
  border: 2px solid #ff9d9d;
}

.contact-result-message.success .icon .fa-check-circle {
  display: inline-block;
  font-size: 60px;
  color: #55bd00;
}

.contact-result-message.success .icon .fa-times-circle {
  display: none;
}

.contact-result-message.failure .icon .fa-times-circle {
  display: inline-block;
  font-size: 60px;
  color: #ff4242;
}

.contact-result-message.failure .icon .fa-check-circle {
  display: none;
}

.contact-result-message-container.shown {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.contact-result-message .text {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 17px;
}

.vacancy-content-wrap .vacancy-content {
  box-shadow: 0px 0px 13px -4px rgb(0 0 0 / 25%) !important;
  border: none !important;
}

@media screen and (max-width: 767px) {
  .vacancy-content-wrap .vacancy-content {
    padding: 25px;
  }
}

.icludesComment div.col-sm-6 {
  flex: 0;
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}
.icludesComment div.col-md-6 {
  flex: 0;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}
.icludesComment div.col-md-6 > ul {
  margin-left: 0 !important;
}

.map-container {
  width: 100%;
  height: 400px;
}

.places-container input {
  height: 45px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  outline: none;
  box-shadow: none;
}

.places-container input:focus {
  border: 1px solid #ff4242;
}

div[data-reach-popover] ul {
  margin-left: 0 !important;
  overflow: hidden;
}

div[data-reach-popover] ul li {
  padding: 0.8rem !important;
  margin-right: 0.5rem;
  position: relative;
  margin: 0 0 0 1.5rem;
  background: transparent;
}

div[data-reach-popover] ul li::before {
  position: absolute;
  content: "";
  top: 0;
  left: -30%;
  width: 200%;
  height: 100%;
  background-color: #000000;
  display: none;
}

div[data-reach-popover] ul li:hover::before {
  display: block;
  z-index: -1;
}

div[data-reach-popover] ul li::marker {
  content: "\f3c5";
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  color: #df1c0e;
}

.vacancy-content .filter-btn {
  box-shadow: none;
  padding-top: 12px;
  padding-bottom: 0;
  transition: all 0.4s;
}

.vacancy-content .filter-btn:last-child {
  padding-bottom: 18px;
}

.vacancy-content .filter-btn.active,
.vacancy-content .filter-btn:hover {
  color: var(--primaryColor);
}

.client-slider .slick-slide {
  min-width: 200px !important;
}

.search-tabs-container ul li .nav-link.active,
.search-tabs-container ul li .nav-link:hover {
  background-color: var(--primaryColor) !important;
  color: #ffffff !important;
}

.quote_section {
  margin-bottom: 100px;
}

.quote_form {
  background-color: #f0f0f0;
  padding: 40px;
  border-radius: 10px;
}

@media screen and (max-width: 1200px) {
  .quote_form {
    padding: 25px;
  }
}

@media screen and (max-width: 767) {
  .quote_form {
    padding: 20px;
  }
}

.quote_form .form-group {
  margin-bottom: 25px;
}

.quote_form .head h4 {
  font-size: 14px !important;
}

.quote_form label {
  font-size: 14px !important;
}

.quote_form .input_wrapper {
  position: relative;
}

.quote_form .input_wrapper .icon {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ff6161;
}

.quote_form .input_wrapper input {
  padding-left: 38px;
  outline: none;
  box-shadow: none;
  font-size: 13px;
  border: 1px solid #fdcaca;
  border-radius: 8px !important;
}

.quote_form .input_wrapper select,
.quote_form .input_wrapper textarea {
  border-radius: 8px !important;
  outline: none;
  box-shadow: none;
  border: 1px solid #fdcaca;
  font-size: 13px;
}

.quote_form .input_wrapper input:focus {
  border: 1px solid #ff7070;
}

.quote_form .date {
  height: 45px;
  border: 1px solid #fdcaca;
}

.quote_form .action-btn {
  margin-top: 30px;
  margin-bottom: 10px;
  border: none;
  box-shadow: none;
  background-color: #df1b23;
  color: #ffffff;
  padding: 20px 30px;
  border-radius: 8px;
  transition: all 0.4s;
}

.quote_form .action-btn:hover {
  background-color: #ff6161;
  transform: scaleX(1.03);
}

.desc-special a {
  color: red;
  padding-left: 5px;
}
.mobile-menu-container {
  font-size: 23px;
  color: #fff;
  text-align: center;
}
.mobile-menu-container .btnMenu {
  font-size: 20px;
  cursor: pointer;
}
.resp-menu {
  background-color: #fff;
}
.resp-menu ul {
  display: grid;
}
.resp-menu li {
  border-top: 1px solid #e6e6e6;
}
.resp-menu a {
  color: #000 !important;
}

.resp-menu li ul {
  width: 50% !important;
}

.resp-menu li ul a:hover {
  color: #fff !important;
}
.resp-menu li ul li a:hover {
  color: #fff !important;
}

.text-hover {
  color: #ffffff;
}

.text-hover:hover {
  color: #ffa6a9;
}

.package-side-item {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.package-side-item figure {
  position: relative;
  width: 30%;
  overflow: hidden;
}

.package-side-item .wrapper {
  width: 70%;
  text-align: left;
}

.package-side-item figure span {
  width: 100% !important;
}

.package-side-item img {
  width: 100% !important;
  height: 62px !important;
  object-fit: cover;
}

.package-side-item .pack-name::before {
  display: none;
}

.package-side-item .pack-name {
  padding-left: 0 !important;
  text-align: left;
  margin-bottom: 5px !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.package-side-item .price {
  text-align: left;
}

.header-social li span:hover {
  cursor: pointer;
}

.agents-section .pagination {
  margin: 40px 0 0 !important;
  flex-wrap: wrap;
}

.agents-section .pagination li {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  gap: 0 !important;
  margin-bottom: 5px !important;
}

.agents-section .pagination li.active a,
.agents-section .pagination li:hover a {
  color: #ffffff !important;
  font-weight: 600;
}

.css-loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--primaryColor);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.next-link.next-link-white a {
  color: #ffffff;
  transition: all 0.3s linear;
}

.next-link.next-link-white a:hover {
  color: var(--primaryColor);
}

.text-white-75 {
  color: #d6d6d6;
}

.gap-8px {
  gap: 8px !important;
}

.gap-10px {
  gap: 10px !important;
}

.gap-20px {
  gap: 20px !important;
}

.css-spinner {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.css-spinner.sm {
  width: 20px;
  height: 20px;
  border-width: 3px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-fix {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
}

.fixed-message {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  opacity: 0;
  bottom: -105%;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  transition: all 1s linear;
}

.fixed-message.success {
  background-color: #0bc404;
}

.fixed-message.error {
  background-color: #fa7672;
}

.fixed-message.shown {
  opacity: 1;
  bottom: 0;
}
